import React, { useState, useEffect, useRef } from 'react'
import styled, { keyframes } from 'styled-components';
import Button from '../components/Button';
import { MdCheck } from 'react-icons/md';
import { min } from 'rxjs';

interface Prop{
  history?: any
}
const Home : React.FC<Prop>=(prop)=>{
  const [ show, setshow ] = useState(false)

  const midDiv = useRef<HTMLDivElement>(null);
  const executeScroll = () => {
    midDiv.current?.scrollIntoView({ behavior: "smooth" });
  };
  
  return(
    <MainContainer>
      <Column>
          <Header style={{color:"#000"}}>ZappletZ</Header>
          <Column style={{ alignItems: 'flex-start'}}>

            <CaptionItem><CaptionItem style={{flex:0.15}}><MdCheck color='#8D56DC' fontSize={40} /></CaptionItem><CaptionItem style={{flex:1}}> Never lose your password</CaptionItem></CaptionItem>
            <CaptionItem><CaptionItem style={{flex:0.15}}><MdCheck color='#8D56DC' fontSize={40} /></CaptionItem><CaptionItem style={{flex:1}}> Access your passwords anywhere</CaptionItem></CaptionItem>
            <CaptionItem><CaptionItem style={{flex:0.15}}><MdCheck color='#8D56DC' fontSize={40} /></CaptionItem><CaptionItem style={{flex:1}}> Generate strong passwords</CaptionItem></CaptionItem>
            <CaptionItem><CaptionItem style={{flex:0.15}}><MdCheck color='#8D56DC' fontSize={40} /></CaptionItem><CaptionItem style={{flex:1}}> Save up to 100 passwords for free</CaptionItem></CaptionItem>
            <CaptionItem><CaptionItem style={{flex:0.15}}><MdCheck color='#8D56DC' fontSize={40} /></CaptionItem><CaptionItem style={{flex:1}}> Backup your passwords</CaptionItem></CaptionItem>
          </Column>
            <ButtonContainer>
                <Button text="Register" handleClick={()=>prop.history.push("/register")}/>
                <Button text="Login" handleClick={()=>prop.history.push("/login")}/>
            </ButtonContainer>
            <ArrowDownDiv onClick={executeScroll}>
              <DownArrow> </DownArrow>
            </ArrowDownDiv>
          <br/>
          <br/>

          </Column>
          <ColumnHowtouse ref={midDiv}>
            <SubHeader>Store passwords securely</SubHeader>
            <ul>
            <LI>Register an account with your email and password. This password is only for logging into zappletz.com.</LI>
            <LI>To save information, go to the profile page and generate encryption keys. Choose a strong "Passphrase."</LI>
            <LI>The "Passphrase" is the only information you need to remember. It is not stored anywhere.</LI>
            <LI>If you forget or lose your passphrase, all your stored information will be lost.</LI>
            <LI>You can reset your passphrase by generating new keys, but you will lose your previously saved information.</LI>
            <LI>To save passwords click on the Save menu enter your infomation and save.</LI>
            <LI>To retrieve passwords, click on the Retrieve menu, enter your passphrase, and press enter</LI>
            </ul>

          <br/>
          <br/>


        </ColumnHowtouse>

      {/* <AIChat 
        userid="ULWHH"
        supportEmail="support@miamigo.ai"
        supportPhone="555-555-5555"
        chatbotname="Chatbot"
        title='AI Chatbot'
        handleQuestion={(q: any)=>handlequestion(q)}
      /> */}
    </MainContainer>
  );
}

const arrowAnimation = keyframes`
    0% { -webkit-transform: scale(1); }
    50% { -webkit-transform: scale(1.05);}
    100% { -webkit-transform: scale(1);}
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  justify-content: center;
  align-items: center;
`
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const Column = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #150734;
  // width: min(500px, 90%);
  @media (max-width: 770px) {
    padding: 25px 50px;
  }
`

const ColumnHowtouse = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #150734;
  width: min(500px, 90%);
  @media (max-width: 770px) {
    padding: 25px 50px;
  }
`
const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  // flex: 1;
  color: #150734;
  font-size: 70px;
  font-weight: 900;
  padding: 10px;
  text-align: center;
  @media (max-width: 770px) {
    font-size: 3rem;
  }
`;

const SubHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: #150734;
  font-size: 30px;
  font-weight: 900;
  padding: 10px;
  text-align: center;
  @media (max-width: 770px) {
    font-size: 3rem;
  }
`;

const CaptionItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  padding: 10px;
`;

const LI = styled.li`
  padding: 7px;
  font-size: 18px;
`;

const ArrowDownDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: 10px;
  // width: 80%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  @media (min-width: 770px) {
    display: none;
  }
`;

const DownArrow = styled.div`
  border: solid #8D56DC;
  border-width: 0 10px 10px 0;
  border-radius: 3px;
  padding: 15px;
  opacity: 1;
  animation: ${arrowAnimation} 1s infinite linear;
}`;

export default Home;