import React, {useState, useEffect} from 'react'
import styled, { keyframes } from 'styled-components';
import { RiArrowDownSLine } from 'react-icons/ri';
import { RiArrowRightSLine } from 'react-icons/ri';

interface Prop{
    history?: any;
    q: string;
    a: any;
}

const QuestionComp:React.FC<Prop> = ({q, a}) => {
    const [show, setshow] = useState(false);
    return(
        <Column style={{padding: "10px 0"}}>
        <QuestionContainer onClick={()=>setshow(!show)}>
            {
            show?
                <ArrowDown>
                    <RiArrowRightSLine fontSize={"22px"} color={"#28559A"}/>
                </ArrowDown>
                :
                <ArrowRight>
                    <RiArrowRightSLine fontSize={"22px"} color={"#28559A"}/>
                </ArrowRight>
            }
            <Question>{q}</Question>
        </QuestionContainer>
        {
            show?
        <QuestionContainer>
            <Answer>
                {a}
            </Answer>
        </QuestionContainer>
        :<></>
        }

        </Column>
    )
}


const FAQs : React.FC<Prop>=({})=>{



    const getSublevels = () => {
        return (
            <>
                There are 3 levels of subscription<ul><li>Basic: Free</li><li>Preferred: $10/month</li><li>Premium: $20/month</li></ul>
            </>
        )
    }

  return(
    <MainContainer>
        <Column>
            <Header>Frequently Asked Questions</Header>
            <QuestionComp q='How do I register an account on zappletz.com?' a={"To register visit http://zappletz.com/register"} />
            <QuestionComp q='How do I save information on zappletz.com?' a={"To save information, click on save menu on the Dashboard. Enter the Label for the information, associated username or email and the password."} />
            <QuestionComp q={"What is a 'Passphrase' and why is it important?"} a={`All information is encrypted and saved on our database. Only your 'Passphrase' can decode that information. It is not stored anywhere, ensuring your data's security. The "Passphrase" is the only information you need to remember to access your saved data.`} />
            <QuestionComp q='What happens if I forget or lose my passphrase?' a={"If you forget or lose your passphrase, all your stored information will be lost and cannot be recovered."} />
            <QuestionComp q='Can I reset my passphrase?' a={"Yes, you can reset your passphrase by generating new encryption keys. However, this will erase all previously saved information."} />
            <QuestionComp q='How do I retrieve passwords on zappletz.com?' a={"To retrieve passwords, click on the Retrieve menu, enter your passphrase, and press enter."} />
        </Column>

    </MainContainer>
  );
}

const showAnimation = keyframes`
0% {
    opacity: 0
  }
  50% {
    opacity: 0.5
  }
  100% {
    opacity: 1;
  }
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}`;

const MainContainer = styled.div`
display: flex;
min-height: 85vh;
justify-content: center;
align-items: flex-start;
padding: 50px;
color: #ffffff;
@media(max-width: 900px){
  flex-direction: column-reverse;
  align-items: center;
}
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const InfoRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 5px;
`

const Column = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  color: #ffffff;
  @media (max-width: 900px) {
	padding: 20px;
  }
`

const Header = styled.div`
    padding: 10px;
    font-weight: 700;
    font-size: 40px;
    justify-content: flex-end;
    color: black;
`;

const QuestionContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: black;
    animation: ${showAnimation} 0.5s linear;
`;

const Question = styled.div`
    font-weight: 400;
    font-size: 18px;
    justify-content: flex-end;
    color: #4B9FE1;
    cursor: pointer;
`;
const ArrowRight =styled.div`
  width: 20px;
`;
const ArrowDown =styled.div`
    width: 20px;
    opacity: 1;
    transform: rotate(90deg);
    animation: ${spin} 0.25s linear;
`;
const Answer = styled.div`
    margin: 10px 0 0 0;
    padding: 0px 20px;
    font-weight: 400;
    font-size: 15px;
    justify-content: flex-end;
    line-height: 2;
`;

const OrderedList = styled.ol`
    margin: 10px 0 0 0;
    padding: 0px 20px;
`;

const List = styled.li`
    margin: 0px;
    font-weight: 400;
    font-size: 15px;
    color: black;
`;

export default FAQs