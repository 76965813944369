import React, {useState, useEffect} from 'react'
import styled, { keyframes } from 'styled-components'
import InputComp from '../../components/InputComp'
import Button from '../../components/Button'
import { fetchWrapper } from '../../utils/apiHandlers'
import { FaSquare } from "react-icons/fa";
import { generateStrongPassword, checkPasswordStrength } from '../../utils/helperfunctions';

interface Prop{
}

const Howstrongisyourpassword : React.FC<Prop>=({})=>{

    const [ pwd, setpwd ] = useState("")
    const [ score, setscore ] = useState(0)
    const [ inhackedlist, setinhackedlist ] = useState("")
    
    
    const checkispwdinhackedlist = async () => {
        if(pwd=="")return
        if(score==6){
            setinhackedlist(`The password entered is very strong`)
            return
        }
        if(pwd.length<6){
            setinhackedlist(`The password entered is too short`)
            return
        }
        try{
            let response = await fetchWrapper.post("/api/hackedlist", {pwd:pwd})
            if(response.idx==-1)
            {
                setinhackedlist(`NOT in common password List`)
            }else{
                setinhackedlist(`Password entered is ranked ${response.idx} in common password list`)
            }
        }catch(error){
            console.log(`error : ${error}`)            
        }
    }

    const changepwd = (e: any)=>{
        setpwd(e.target.value)
        setscore(checkPasswordStrength(e.target.value))
        setinhackedlist("")
    }

    const getpwd = () => {
        let pwd = generateStrongPassword(16)
        setpwd(pwd)
        setscore(checkPasswordStrength(pwd))
    }

  return(
    <MainContainer>
        <Column>
            <Header>How Strong is your Password</Header>

            <Paragraph>
                You probably have hundreds of online accounts and if you dont have a password manager or a systematic way to manage your passwords most likely you are either using a same password or a some variation of a password for every account. Every security expert will tell you DO NOT REUSE PASSWORDS. Week passwords are the ones that are either common passwords or are easy to guess. The top 5 common and reused passwords are 123456, password, 12345678, qwerty, and 123456789. Easy to guess passwords on the other hand are a combination of your name and date of birth with some easy to replace special character like 0 in place of o; for e.g. passw0rd is ranked 411 in common password list. You may check how strong is  your password by entering it below, you may also check if it in the top 100k common password list.
                </Paragraph>
                <InputComp id="checkpwd" placeholder='Enter Password' value={pwd} onChange={(e:any)=>changepwd(e)}  />
            {
                score==0?
                <span>
                    <FaSquare color="gray" fontSize={"16px"} />
                    <FaSquare color="gray" fontSize={"16px"} />
                    <FaSquare color="gray" fontSize={"16px"} />
                    <FaSquare color="gray" fontSize={"16px"} />
                    <FaSquare color="gray" fontSize={"16px"} />
                    <FaSquare color="gray" fontSize={"16px"} />
                </span>
                :score==1?
                <span>
                    <FaSquare color="green" fontSize={"16px"} />
                    <FaSquare color="gray" fontSize={"16px"} />
                    <FaSquare color="gray" fontSize={"16px"} />
                    <FaSquare color="gray" fontSize={"16px"} />
                    <FaSquare color="gray" fontSize={"16px"} />
                    <FaSquare color="gray" fontSize={"16px"} />
                </span>
                :score==2?
                <span>
                    <FaSquare color="green" fontSize={"16px"} />
                    <FaSquare color="green" fontSize={"16px"} />
                    <FaSquare color="gray" fontSize={"16px"} />
                    <FaSquare color="gray" fontSize={"16px"} />
                    <FaSquare color="gray" fontSize={"16px"} />
                    <FaSquare color="gray" fontSize={"16px"} />
                </span>
                :score==3?
                <span>
                    <FaSquare color="green" fontSize={"16px"} />
                    <FaSquare color="green" fontSize={"16px"} />
                    <FaSquare color="green" fontSize={"16px"} />
                    <FaSquare color="gray" fontSize={"16px"} />
                    <FaSquare color="gray" fontSize={"16px"} />
                    <FaSquare color="gray" fontSize={"16px"} />
                </span>
                :score==4?
                <span>
                    <FaSquare color="green" fontSize={"16px"} />
                    <FaSquare color="green" fontSize={"16px"} />
                    <FaSquare color="green" fontSize={"16px"} />
                    <FaSquare color="gray" fontSize={"16px"} />
                    <FaSquare color="gray" fontSize={"16px"} />
                    <FaSquare color="gray" fontSize={"16px"} />
                </span>
                :score==5?
                <span>
                    <FaSquare color="green" fontSize={"16px"} />
                    <FaSquare color="green" fontSize={"16px"} />
                    <FaSquare color="green" fontSize={"16px"} />
                    <FaSquare color="green" fontSize={"16px"} />
                    <FaSquare color="green" fontSize={"16px"} />
                    <FaSquare color="gray" fontSize={"16px"} />
                </span>
                :score==6?
                <span>
                    <FaSquare color="green" fontSize={"16px"}/>
                    <FaSquare color="green" fontSize={"16px"}/>
                    <FaSquare color="green" fontSize={"16px"}/>
                    <FaSquare color="green" fontSize={"16px"}/>
                    <FaSquare color="green" fontSize={"16px"}/>
                    <FaSquare color="green" fontSize={"16px"}/>
                </span>:<></>
            }
            <SubHeader1>{inhackedlist}</SubHeader1>
            <ButtonContainer>
                <Button text="How Common is your Password?" handleClick={()=>checkispwdinhackedlist()} />
                <Button text="Generate Strong Password" handleClick={()=>getpwd()} />
            </ButtonContainer>
            <SubHeader>How are passwords cracked?</SubHeader>
            <Paragraph>
            Passwords can be cracked in many ways and hackers use sophisticated tools and algorithms for this. First a hacker breaks into your account holders website/database. Most reliable websites do not store passwords in plain text but they hass your password making the task to crack your password difficult. Hashing only makes the task of cracking a password difficult it does not make it impossible. Some common strategies hackers employ to crack passwords are:
            </Paragraph>
            <ul>
                <LI><SpanUnderline>Brute Force Attacks</SpanUnderline>: Hackers use automated tools to try every possible combination of characters until they find the correct one. Short and simple passwords can be cracked in seconds.</LI>
                <LI><SpanUnderline>Dictionary Attacks</SpanUnderline>: These attacks involve trying common words, phrases, and variations. Passwords that are words or simple phrases found in dictionaries are particularly vulnerable.</LI>
                <LI><SpanUnderline>Credential Stuffing</SpanUnderline>: If a hacker obtains a username and password from one breach, they will try the same credentials on other sites. If you reuse passwords, this tactic can grant hackers access to multiple accounts.</LI>
                <LI><SpanUnderline>Social Engineering</SpanUnderline>: Hackers may use personal information gleaned from social media and other sources to guess passwords. Birthdays, pet names, and favorite hobbies are often used in passwords and can be exploited.</LI>
            </ul>

            
            <SubHeader>What are the Characteristics of a Strong Passwords?</SubHeader>
            <Paragraph>
            A strong password is one that is difficult for not just people to guess but also computers to guess and crack. Here are some key characteristics that make a password strong:
            </Paragraph>
            <ul>
            <LI><SpanUnderline>Length</SpanUnderline>: The longer the password, the harder it is to crack. Most hackers rely on brute force to crack passwords; a longer password makes the task more difficult and brute forece computation impossible. A strong password should be at least 12 characters long, though 16 or more is preferable.</LI>
            <LI><SpanUnderline>Complexity:</SpanUnderline> A mix of uppercase and lowercase letters, numbers, and special characters (!, @, #, etc.) increases the complexity of a password and makes it more secure. Increasing the choice of characters increases the possibilies many folds.</LI>
            <LI><SpanUnderline>Avoid common and predictable words</SpanUnderline>: Avoid using common words, phrases, or predictable patterns. Passwords like "password123" or "abc123" are easily guessable and highly insecure. Avoid using your name or any information from your profile. If a hacker is breaks into your account holders website, they will get access to your personal infomation implying any passwords based on personal data like name, date of birth and address become easy to crack.</LI>
            <LI><SpanUnderline>Do not reuse passwords</SpanUnderline>: Each password should be unique and not reused across multiple accounts. Using the same password for different sites increases the risk of a security breach if one of those sites is compromised.</LI>
            </ul>
            
            <SubHeader>Best Practices - Use a Password Manager</SubHeader>
            <Paragraph>
            Using a password generator to generate passwords greater than 12 characters greatly increases the strength of your password. Password managers like ZappletZ help you generate and store strong, unique passwords for each of your accounts. This eliminates the need to remember multiple complex passwords. Some things to look for in your password to make sure they are secure are:
            </Paragraph>
            <ul>
                <LI>Your password includes special characters: Adding symbols, numbers, and varying capitalization can significantly enhance password strength. For example, "Fly!ngeag13#inTheSky!" is much stronger than "flyingeagle."</LI>
                <LI>Avoid Common Substitutions: Simply replacing letters with numbers or symbols (e.g., "P@ssw0rd") is not sufficient, as these patterns are easy to predict and incorporated in hacking algorithms.</LI>
            </ul>

            <SubHeader>Managing Passwords</SubHeader>
            <Paragraph>
                Remembering multiple strong passwords can be challenging and therefore most people rely of easy to remember and common passwords and reuse them across different account. This make them vulnerable to their account being hacked into. Using a password manager offer a practical solution to managing passwords that are strong. A password manager securely stores your passwords lets your retrieve them when you need them. With ZappletZ, for example, you only need to remember your master password and secret passphrase, making it easier to maintain strong security across all your accounts.
            </Paragraph>

        </Column>
    </MainContainer>
  );
}


const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 85vh;
    justify-content: flex-start;
    align-items: center;
`
const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 25px;
`;

const ButtonContainer = styled.div`
    display: flex;
`;

const Header = styled.div`
    font-size: 30px;
    font-weight: 700;
`;

const SubHeader = styled.div`
    width: 100%;
    font-size: 22px;
    font-weight: 600;
    text-align: left;
    margin: 7px;
`;

const SubHeader1 = styled.div`
    font-size: 18px;
    font-weight: 500;
    margin: 7px;
`;

const SpanUnderline = styled.span`
    text-decoration: underline;
`;

const Paragraph = styled.p`
    font-size: 16px;
    line-height: 2;
`;

const Image = styled.img`
    width: min(95%, 450px);
`;

const LI = styled.li`
    line-height: 2;
    font-size: 14px;
`;

export default Howstrongisyourpassword