import React, {useState, useEffect} from 'react'
import styled, { keyframes } from 'styled-components'
import SafePwd from "../../assets/how-safe-is-your-password.jpeg"
import { generateStrongPassword } from '../../utils/helperfunctions'

interface Prop{
}


const PWDBlog : React.FC<Prop>=({})=>{
  return(
    <MainContainer>
        <Column>

        <Header>
            Why Password Manager?
        </Header>
        <Paragraph>
            In today's digital age, managing passwords can be a daunting task. With the multitude of online accounts we maintain, from social media to banking, a reliable and secure password manager designed to help users securely save and retrieve their passwords anytime, anywhere is a necessity for everyone. Many users due to the overwhelming number of account and insurmountable task of managing thier passwords tend to use passwords like &quot;111111&quot; or &quot;123455&quot; or even &quot;password&quot; for their account. Even user created diffult passwords that seem unbreakable are easy to crack by hackers in a matter of minutes. A trully safe password that would be impossible to crack should be more than 12 characters and include a combination of lower and upper case and special chacters for e.g. &quot;{generateStrongPassword()}&quot;. A secure password like this cannot be memorized and requires a system to save and retrieve it when needed. A password manager ensures that all saved passwords are encrypted protecting your information from hackers and malicius actors online. Below are some of the feature that are essential for a password manager.
        </Paragraph>

        <Image src={SafePwd} alt="how safe is your password" />

        <SubHeader>
            Security
        </SubHeader>
        <Paragraph>
            One of the most important feature of a password manager is its robust security measures. All passwords saved should be encrypted, ensuring that your sensitive information remains protected at all times. Having said that the security features should not compromise the user friendly aspect of the system. A strong security with ease of use is important for any password manager system.
        </Paragraph>
        <SubHeader>Universal Accessibility</SubHeader>

        {/* Unlike Google Password Manager and Apple Keychain, which are often tied to specific ecosystems, ZappletZ is universally portable. Google Password Manager is integrated into the Google ecosystem, meaning it works seamlessly on Chrome and Android devices. Similarly, Apple Keychain is embedded within the Apple ecosystem, functioning optimally on iOS and macOS devices. While these integrations offer convenience within their respective platforms, they fall short when it comes to cross-platform compatibility. */}
        <Paragraph>

        Clearly accessibility is an important feature of a password manager. Users have many different devices and user different browsers and apps. A password manager should be accessible to user independent of the platform they are on. Whether you're using a Windows PC, a Mac, an Android phone, or an iPad, a password manager should make your data available to you whenever you need them. This universal accessibility feature is a must for users who operate across multiple devices and operating systems.
        </Paragraph>

        <SubHeader>Simplified Password Management</SubHeader>
        <Paragraph>

            A password manager should be straightforward and user-friendly. It should reduce the burden of remembering passwords and make accessing your data easy. The password manager should allow users to create strong passwords and suggest passwords that are week and prone to being hacked.
        </Paragraph>

        <SubHeader>Password Manager Choices</SubHeader>

        <SubHeader1>Google Password Manager</SubHeader1>
        <Paragraph>
            Google Password Manager is a good choice for the Google ecosystem. Google Password Manager is integrated into the Google ecosystem, meaning it works seamlessly on Chrome and Android devices. However, you cannot use this service on other browser of ios apps and app on other platforms.
        </Paragraph>
        <SubHeader1>Apple Keychain</SubHeader1>
        <Paragraph>
            Apple Keychain is embedded within the Apple ecosystem, functioning optimally on iOS and macOS devices. While these integrations offer convenience within its  platforms, you will not be able to use it on non Apple devices and platforms.
        </Paragraph>

        <SubHeader1>ZappletZ</SubHeader1>
        <Paragraph>
            ZappletZ is not confined to a specific ecosystem. Its web-based platform ensures you can access your passwords from any device, anywhere in the world.  
        </Paragraph>
        <Paragraph>
            With encrypted passwords and encrypted decryption keys, ZappletZ offers a high level of security that is the most important requirement for any password manager. Users just need to remember thier passphrase which they create after registration. A strong passphrase is the key to all the data that is stored on the database. Some tips for creting a strong passphrase are:
            <ul>
                <LI>Length: Aim for at least 12-16 characters. Longer passphrases are more secure.</LI>
                <LI>Complexity: Use a mix of uppercase and lowercase letters, numbers, and special characters.</LI>
                <LI>Unpredictability: Avoid common phrases, easily guessable words, and patterns.</LI>
                <LI>Personal Relevance: Make it something personal but not easily associated with you or your interests.</LI>
            </ul>

            here is an example of a strong passphrase:
            "MyGr3atGrandma'sRecipeBook!" &nbsp;
            <span style={{color: "red"}}>WARNING: DO NOT USE THIS PASSPHRASE</span>

        </Paragraph>
        <Paragraph>
            The simple two-password system of ZappletZ reduces the complexity of password management. Resetting your login password is straightforward, although it's crucial to remember your secret passphrase to avoid losing your data. You can quickly retrieve stored passwords reset them and copy information by clicking on the copy button.
        </Paragraph>
        <Paragraph>
            ZappletZ is entirely free, providing premium password management features without any cost. This makes it accessible to a broad audience, from individuals to small businesses.
        </Paragraph>
        <Paragraph>
            In conclusion, ZappletZ.com is a powerful, secure, and universally accessible password manager that stands out in a crowded market. Its dual-layer encryption ensures top-notch security, while its web-based platform offers unparalleled flexibility across devices and operating systems. By simplifying password management to just two essential passwords, ZappletZ makes it easy for users to keep their online accounts secure and accessible.
        </Paragraph>
        </Column>
    </MainContainer>
  );
}


const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 85vh;
    justify-content: flex-start;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px 50px;
    justify-content: flex-start;
    align-items: center;
`;
const Header = styled.div`
    font-size: 30px;
    font-weight: 700;
`;

const SubHeader = styled.div`
    width: 100%;
    font-size: 22px;
    font-weight: 600;
    text-align: left;
    margin: 7px;
`;

const SubHeader1 = styled.div`
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    margin: 7px;
    text-decoration: underline;
`;

const Paragraph = styled.p`
    font-size: 16px;
    line-height: 2;
`;

const Image = styled.img`
    width: min(95%, 450px);
`;

const LI = styled.li`
    line-height: 2;
    font-size: 14px;
`;

export default PWDBlog