import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import Home from "./pages/index";
import Login from "./pages/login";
import Register from "./pages/register";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import FAQs from "./pages/frequently-asked-questions";
import Dashboard from "./pages/dashboard"
import Terms from "./pages/terms-of-service";
import PrivacyPolicy from "./pages/privacy-policy";
import UnsubscribePage from "./pages/unsubscribe";
import Pagenotfound from "./pages/404";
import Blog from "./pages/blogs/index";
import PWDBlog from "./pages/blogs/password-manager-blog";
import Support from "./pages/support";
import StrongPwd from "./pages/blogs/how-strong-is-your-password"

function App() {
  // const location = useLocation();
  // const hideNavAndFooter = location.pathname === '/sitemap.xml';
  return (
      <Router>
        <NavBar />
        <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/frequently-asked-questions" component={FAQs} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/terms-of-service" component={Terms} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/unsubscribe" component={UnsubscribePage} />
        <Route exact path="/blogs" component={Blog} />
        <Route exact path="/blogs/why-password-manager" component={PWDBlog} />
        <Route exact path="/blogs/how-strong-is-your-password" component={StrongPwd} />
        <Route exact path="/support" component={Support} />
        <Route exact path="/404" component={Pagenotfound} />
        <Redirect from="*" to="/404" />
      </Switch>
      <Footer />
      </Router>
  );
}

export default App;
