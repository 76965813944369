import React, {useState, useEffect} from 'react'
import styled, { keyframes } from 'styled-components';
import { IoMdCopy } from "react-icons/io";


interface CopyProp{
    value: any;
}

const CopyIconComp : React.FC<CopyProp>=({value}) => {
    const [ copied, setcopied ] = useState(false)
    const [ timeoutid, settimeoutid ] = useState<any>("")
    const [ clearcopytimeoutid, setclearcopytimeoutid ] = useState<any>("")
    

    const copyvalue = ()=>{
        navigator.clipboard.writeText(value)
    }
    

    const copy = () => {
        setcopied(true)
        copyvalue()
        let timeoutid = setTimeout(()=>{
            setcopied(false)
        }, 2000)
        settimeoutid(timeoutid)

    }

    useEffect(()=>{
        return ()=>{
            clearTimeout(timeoutid)
            clearTimeout(clearcopytimeoutid)
        }
    })

    
    return(
        <IoMdCopy fontSize={'large'} onClick={()=>copy()} color={copied?"#4B9FE1":""} />
    )
}

export default CopyIconComp