import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components';
import styles from "./Button.module.css";

interface Buttonprops {
    text: string;
    handleClick?:(x?: any)=>void;
    backgroundColor?: string;
    width?:any;
}

const Button: React.FC<Buttonprops> = ({text, backgroundColor, handleClick, width}) => {
    const btnclick = (e:any) => {
        if(handleClick)
        {
            handleClick()

        }
    }

    return (
        <MainContainer >
            <div className={`${styles.container} ${styles.ripple}`} onClick={btnclick} style={{backgroundColor:backgroundColor?backgroundColor:"#4B9FE1", width:width?width:"auto"}}>
                {text}
            </div>
        </MainContainer>
    )
}

const MainContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`;



export default Button;