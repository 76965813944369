import React from 'react'
import styled from 'styled-components'
import img from "../../assets/logo.png";

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <Link href={"/"} style={{display:'flex', flex:3}}>
        {/* <Header>ZappletZ</Header> */}
          <img src={img} width="100" height={"100"} style={{cursor:"pointer"}} alt={""}/>
      </Link>
      <FooterLinks style={{display:'flex', flex:5}}>
        <StyledLink href={'/blogs'} rel="noreferrer nofollow"> Blogs </StyledLink>
        <StyledLink href={'/support'} rel="noreferrer nofollow"> Support </StyledLink>
        <StyledLink href={'/frequently-asked-questions'}> FAQ </StyledLink>
        <StyledLink href={'/terms-of-service'} rel="noreferrer nofollow"> Terms </StyledLink>
        <StyledLink href={'/privacy-policy'} rel="noreferrer nofollow"> Privacy </StyledLink>
      </FooterLinks>
    </FooterContainer>
  )
}

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 20px;
  color: #150734;
  margin: 20px 0 ;
  @media (max-width: 770px) {
    flex-direction: column;
    align-items: center;
    height: auto;
  }
`

const FooterLinks = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // margin: 5px;
  flex-wrap: wrap;
  color: #150734;
  @media (max-width: 400px) {
    flex-direction: column;
    align-items: center;
    height: auto;
  }
`

const StyledLink = styled.a`
  text-decoration: none;
  color: #150734;
  margin: 5px 20px;
  padding: 5px;
  border-radius: 3px;
  transition: ease 0.5s;
  &:hover {
    background-color: #63BCE5;
    color: #FFFFFF;
  }
`
const Link = styled.a`
`;

const Header = styled.h1`
  font-size: 45px;
  font-weight: 900;
  text-align: center;
  margin: 5px;
`;

export default Footer
