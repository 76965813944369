import { useEffect, useState } from "react";
import styled from "styled-components";
import MessageModal from "../components/MessageModal";
import Button from "../components/Button";
import InputComp from "../components/InputComp";
import { fetchWrapper } from "../utils/apiHandlers";
// import { clientLogger } from ../"utils/clientLogger";

interface Props{
  history?: any;
}

const UnsubscribePage: React.FC<Props> = ({history}) => {

  const [email, setemail] = useState<any>('');
  const [modalheader, setmodalheader] = useState('');
  const [modalmessage, setmodalmessage] = useState('');
  const [modalstate, setmodalstate] = useState(false);
  const [ unsubscribedone, setunsubscribedone ] = useState(false)
  

  const openModal = (header: string, message: string, progress?: boolean) => {
    setmodalheader(header)
    setmodalmessage(message);
    setmodalstate(true);
  }


  const submit = async () => {
    if(email==""){
      openModal("Warning", "Email is required.");
      return;
    }
    try{
      const response = await fetchWrapper.post("/api/authenticate/unsubscribe", {email: email}).then(r=>r)
      console.log(`response : ${JSON.stringify(response)}`)
      
        openModal("Message", "You have successfully unsubscribed to email notifications.");
        setunsubscribedone(true)
    }catch(error){
      openModal("Message", "You have successfully unsubscribed to email notifications.");
      setunsubscribedone(true)
    }
  }

  const changeemail = (e: any) => {
    setemail(e.target.value.toLowerCase());
  }


  useEffect(() => {
    console.log(history.location)
    
    let unsubscribeemail = history.location.search.split("=")[1]
    setemail(unsubscribeemail)

  }, [history]);

  useEffect(()=>{
  },[email])

  return (
    <>
      <MainContainer >
          <MessageModal header={modalheader} message={modalmessage} state={modalstate} handleClose={()=> setmodalstate(false)} />
          <Column>
          {unsubscribedone?
          <>
            <Header>You are unsubscribed.</Header>
          </>:
          <>
            <Header>Unsubscribe</Header>
            <InputComp id="loginemail" placeholder="email" value={email} onChange={(e) => changeemail(e)} />
            <Button handleClick={()=>submit()} text="Unsubscribe" />
          </>
          }
          </Column>
      </MainContainer>
    </>
  );
};

const MainContainer = styled.div`
  display: flex;
  padding: 50px;
  flex-direction: column;
  align-items: center;
  min-height: 75vh
`
const Column = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 900px) {
	padding: 20px;
  }
`
const Header = styled.div`
    padding: 10px;
    font-weight: 300;
    font-size: 40px;
    justify-content: flex-end;
    color: black;
`;

export default UnsubscribePage;
