import { useEffect, useState } from "react";
import styled from "styled-components";
import MessageModal from "../components/MessageModal";
import Button from "../components/Button";
import { userContext } from '../usercontext';
import Input from "../components/InputComp";
import { fetchWrapper } from "../utils/apiHandlers";
import { validateEmail } from "../utils/helperfunctions";

interface Props{
  history?: any;
}

const LoginPage: React.FC<Props> = (props) => {
  
  const [user, setUser] = useState<any>(null)
  const [email, setemail] = useState('')
  const [password, setpassword] = useState('')
  const [modalheader, setmodalheader] = useState('')
  const [modalmessage, setmodalmessage] = useState('')
  const [modalprogress, setmodalprogress] = useState(false)  
  const [modalstate, setmodalstate] = useState(false)
  const [pwdsent, setpwdsent] = useState(false)

  const openModal = (header: string, message: string, progress?: boolean) => {
    setmodalheader(header)
    setmodalmessage(message)
    setmodalstate(true)
    setmodalprogress(progress?progress: false)
    
  }

  const onloginenter = (e: any) =>{
      if(e.key === "Enter"){
        submit()
      }
    }

  const submit = async () => {

    openModal("Authenticating", "", true)

    let sendemail = email.replace(/\s/g, '')

    if(sendemail=="" || password==""){
      openModal("Warning", "Invalid fields.")
      return;
    }
    console.log(`Here submit`)
    try{
      const user: any = await userContext.login(sendemail, password)
      // console.log(`status ${JSON.stringify(user)}`)
      if(user.status==200){
          props.history.push("/profile")
          return;
      }
      openModal("Login failed", "Invalid credentials")
    }catch(error){
      //console.log(`catch login error ${JSON.stringify(error)}`)
      openModal("Login failed", "Invalid credentials")
    }
  }

  const changeemail = (e: any) => {
    setemail(e.target.value.toLowerCase())
    setpwdsent(false)
  }

  const sendtemppassword = async () => {
    if(pwdsent){
      openModal("Message", "A temporary password has been sent to your email.")
      return;
    }

    if(email==""){
      openModal("Warning", "Email is required.")
      return;
    }

    if(!validateEmail(email)){
      openModal("Warning", "Email is not valid.")
      return;
    }
    try{
      await fetchWrapper.post("/api/authenticate/gettemppwd", {email: email}).then((res)=>{
        openModal("Message", "A temporary password has been sent to your email.")
      }).catch((error)=>{
          openModal("Message", "If an account was found, a temporary password was sent to your email.")
      })
    }catch(error){
      openModal("Message", "If an account exists corresponsing to this email, a temporary password will be sent to your email.")
      console.log(`Here `);
    }
  }

  useEffect(() => {
    const subscription = userContext.user.subscribe((x:any) => setUser(x))
    return () => subscription.unsubscribe()
  }, [])

  useEffect(()=>{
    // console.log(`user ${JSON.stringify(user)}`)
    if(!user)return;
    if(user.status==200){
      props.history.push("/dashboard")
    }
  },[user])

  return (
    <>
      <MainContainer onKeyPress={(e) =>{onloginenter(e)}}>
          <MessageModal header={modalheader} message={modalmessage} state={modalstate} handleClose={()=> setmodalstate(false)} progress={modalprogress} />
          <Column>
          <Header>Login</Header>
          <Input id="loginemail" placeholder="email" value={email} onChange={(e: any) => changeemail(e)} />
          <Input id="loginpwd" type="password" placeholder="password" value={password} onChange={(e) => setpassword(e.target.value)} />
          <Button handleClick={()=>submit()} text="Login" />
          <div style={{margin:"7px"}}>Don&apos;t have an account yet?<Button text="Register" handleClick={()=>props.history.push("/register")} /></div>
          <div style={{margin:"7px"}}>Forgot your password? Get new password <span onClick={()=>{sendtemppassword()}} style={{color: "#63BCE5", textDecoration:"underline", cursor:"pointer"}}>Email Password</span></div>
          <div style={{margin:"7px"}}>Want to change password? <span onClick={()=>props.history.push("/change-password")} style={{color: "#63BCE5", textDecoration:"underline", cursor:"pointer"}}>Change Password</span></div>

          </Column>
      </MainContainer>
    </>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 85vh;
  justify-content: center;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const InfoRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 5px;
`

const Column = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  @media (max-width: 770px) {
    padding: 20px 50px;
  }
`

const Header = styled.div`
    padding: 10px;
    font-weight: 700;
    font-size: 40px;
    justify-content: flex-end;
    color: black;
`;

export default LoginPage;
