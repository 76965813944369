import { useEffect, useState } from "react";
import styled from "styled-components";
import MessageModal from "../components/MessageModal";
import { validateEmail } from "../utils/helperfunctions";
import Button from "../components/Button";
import { fetchWrapper } from "../utils/apiHandlers";
import { userContext } from "../usercontext";

interface Props{
  history?: any;
}

const Register: React.FC<Props> = (props) => {
  const [user, setUser] = useState<any>(null)
  const [email, setemail] = useState('');
  const [password, setpassword] = useState('');
  const [modalheader, setmodalheader] = useState('');
  const [modalmessage, setmodalmessage] = useState('');
  const [modalstate, setmodalstate] = useState(false);
  const [verificationcode, setverificationcode] = useState('');
  const [coderequested, setcoderequested] = useState(false);
  const [disableemail, setdisableemail] = useState(false);
  const [ registrationsuccessful, setregistrationsuccessful ] = useState(false)
  

  const openModal = (header: string, message: string, progress?: boolean) => {
    setmodalheader(header)
    setmodalmessage(message);
    setmodalstate(true);
  }

  const register = async () => {

    let response = await userContext.register(email, verificationcode, password).then((r: any)=>r)
    console.log(`response : ${JSON.stringify(response)}`)
    
    if(!response){
      openModal("Warning", "Invalid code.")
      return
    }
    setregistrationsuccessful(true)
    // props.history.push("/dashboard")
  }

  const requestcode = async () => {
    if(!validateEmail(email)){
      openModal("Error", "Invalid email address.")
      return;
    }

    const body = {
        email: email,
    }
    await fetchWrapper.post("/api/authenticate/getemailverificationcode", body).then((res:any) => {
      if(res.status==200){
          setcoderequested(true);
          setdisableemail(true);
          openModal("Message", res.msg);
        }else{
          openModal("Error", res.msg);
      }

    }).catch((error:any)=> {
        console.log(error)
        openModal("Error", "Failed, please try again.");
      })
  }

  useEffect(()=>{
    if(!registrationsuccessful)return
    let user: any = localStorage.getItem("user")
    
    user = JSON.parse(user)
    if(user && user.status==200){
      props.history.push("/dashboard")
    }
  }, [registrationsuccessful])

  useEffect(() => {
    const subscription = userContext.user.subscribe((x:any) => setUser(x))
    return () => subscription.unsubscribe()
  }, [])

  useEffect(()=>{
    // console.log(`user ${JSON.stringify(user)}`)
    if(!user)return;
    if(user.status==200){
      props.history.push("/dashboard")
    }
  },[user])

  return(
    <MainContainer>
          <MessageModal header={modalheader} message={modalmessage} state={modalstate} handleClose={()=> setmodalstate(false)}/>
          <Header>Register</Header>
          <Input placeholder="email" value={email} onChange={(e) => setemail(e.target.value)} disabled={disableemail}  autoComplete={"off"} />
          {!coderequested?
            <Button handleClick={requestcode} text="Request Code"/>
            :<>
            <Input placeholder="verification code" value={verificationcode} onChange={(e) => setverificationcode(e.target.value)} />
            <Input type="password" placeholder="password" value={password} onChange={(e) => setpassword(e.target.value)} autoComplete={"new-password"} />
            <Button handleClick={register} text="Register"/>
            </>
          }

          <br/>
          <br/>

            <SubHeader>Store passwords securely</SubHeader>
            <ul>
            <LI>Register an account with your email and password. This password is only for logging into zappletz.com.</LI>
            <LI>To save information, go to the profile page and generate encryption keys. Choose a strong "Passphrase."</LI>
            <LI>The "Passphrase" is the only information you need to remember. It is not stored anywhere.</LI>
            <LI>If you forget or lose your passphrase, all your stored information will be lost.</LI>
            <LI>You can reset your passphrase by generating new keys, but you will lose your previously saved information.</LI>
            <LI>To save passwords click on the Save menu enter your infomation and save.</LI>
            <LI>To retrieve passwords, click on the Retrieve menu, enter your passphrase, and press enter</LI>
            </ul>
  </MainContainer>
  );
};

const LI = styled.li`
  padding: 7px;
  font-size: 14px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 85vh;
  justify-content: center;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const InfoRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 5px;
`

const Column = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
  flex: 1;
  @media (max-width: 770px) {
    padding: 20px 50px;
  }
`
const Header = styled.div`
    padding: 10px;
    font-weight: 700;
    font-size: 40px;
    justify-content: flex-end;
    color: black;
`;

const SubHeader = styled.div`
    font-weight: 700;
    font-size: 25px;
    justify-content: flex-end;
`;

const ButtonDiv = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
    padding: 10px;
    background-color: #eee;
    border: 1px solid black;
    margin: 10px;
    width: 250px;
    border-radius: 3px;
`

export default Register;
