import React from "react";
import styled, { keyframes } from "styled-components";

const BounceAnimation = keyframes`
  0% { margin-bottom: 0; }
  50% { margin-bottom: 15px }
  100% { margin-bottom: 0 }
`;
const DotWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  height: 25px;
`;
const Dot = styled.div`
  background-color: purple;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  animation: ${BounceAnimation} 0.5s linear infinite;
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}`;

const LoadingDiv = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255,166,0,.3);
  border-radius: 50%;
  border-top-color: #FFA600;
  animation: ${spin} 1s linear infinite;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoadingComponent: React.FC<{}> = ({}) => {
    return (
      <Container>
      <LoadingDiv />
      <br/>
      Please wait
      </Container>
      // <DotWrapper>
      //   <Dot style={{animationDelay:'0s'}} />
      //   <Dot style={{animationDelay:'0.1s'}}  />
      //   <Dot style={{animationDelay:'0.2s'}}  />
      // </DotWrapper>
    )
}
export default LoadingComponent