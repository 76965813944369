import React from "react";
import styled from "styled-components";

const HorizontalLine = ()=>{

    return(
        <HR />
    )

}

const HR = styled.div`
    display: flex;
    align-self: center;
    margin: 20px 0;
    width: 95%;
    border-top: 1px solid lightgray;
`;

export default HorizontalLine