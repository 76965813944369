import React from "react";
import styled from "styled-components";

interface OwnProps {
  history: any;
}
type Props = OwnProps;

const PrivacyPolicy = (props: Props) => {
  return (
      <MainContainer>
        <Section>
          <Header>Privacy Policy</Header>
          <Paragraph>
            This Privacy Policy Governs the manner in which ZappletZ
            referred to as &quot;ZappletZ&quot; collects, uses, maintains and discloses
            information collected from Users (each, a &quot;User&quot;) of all products
            and services offered by ZappletZ.
          </Paragraph>

          <Paragraph>
            <Title>Personal identification information</Title>
            We may collect personal identification information from Users in a
            variety of ways, including, but not limited to, when Users visit our
            site, register on the site, and in connection with other activities,
            services, features or resources we make available on our Site. Users
            may be asked for, as appropriate, name, email address. We will
            collect personal identification information from Users only if they
            voluntarily submit such information to us. Users can always refuse
            to supply personally identification information, except that it may
            prevent them from engaging in certain Site related activities.
          </Paragraph>

          <Paragraph>
            <Title>Non-personal identification information</Title>
            We may collect non-personal identification information about Users
            whenever they interact with our Site. Non-personal identification
            information may include the browser name, the type of computer and
            technical information about Users means of connection to our Site,
            such as the operating system and the Internet service providers
            utilized and other similar information.
          </Paragraph>

          <Paragraph>
            <Title>Web browser cookies</Title>
            Our Site may use &quot;cookies&quot; to enhance User experience. User&apos;s web
            browser places cookies on their hard drive for record-keeping
            purposes and sometimes to track information about them. User may
            choose to set their web browser to refuse cookies, or to alert you
            when cookies are being sent. If they do so, note that some parts of
            the Site may not function properly.
          </Paragraph>

          <Paragraph>
            <Title>How we use collected information</Title>
            ZappletZ may collect and use Users personal information for the
            following purposes:
            <br />
            To improve customer service Information you provide helps us respond
            to your customer service requests and support needs more
            efficiently.
            <br />
            To personalize user experience We may use information in the
            aggregate to understand how our Users as a group use the services
            and resources provided on our Site.
          </Paragraph>

          <Paragraph>
            <Title>How we protect your information</Title>
            We adopt appropriate data collection, storage and processing
            practices and security measures to protect against unauthorized
            access, alteration, disclosure or destruction of your personal
            information, username, password, transaction information and data
            stored on our Site. Sensitive and private data exchange between the
            Site and its Users happens over a SSL secured communication channel
            and is encrypted and protected with digital signatures.
          </Paragraph>

          <Paragraph>
            <Title>Sharing your personal information</Title>
            We do not sell, trade, or rent Users personal identification
            information to others. We may share generic aggregated demographic
            information not linked to any personal identification information
            regarding visitors and users with our business partners, trusted
            affiliates and advertisers for the purposes outlined above.
          </Paragraph>

          <Paragraph>
            <Title>Changes to this privacy policy</Title>
            ZappletZ has the discretion to update this privacy policy at any
            time. When we do, we will post a notification on the main page of
            our Site, revise the updated date at the bottom of this page and
            send you an email. We encourage Users to frequently check this page
            for any changes to stay informed about how we are helping to protect
            the personal information we collect. You acknowledge and agree that
            it is your responsibility to review this privacy policy periodically
            and become aware of modifications.
          </Paragraph>

          <Paragraph>
            <Title>Your acceptance of these terms</Title>
            By using this Site, you signify your acceptance of this policy. If
            you do not agree to this policy, please do not use our Site. Your
            continued use of the Site following the posting of changes to this
            policy will be deemed your acceptance of those changes.
          </Paragraph>

          <Paragraph>
            <Title>Contact us</Title>
            If you have any questions about this Privacy Policy, the practices
            of this site, or your dealings with this site, please contact us{" "}
            <Link href="/contact"><span>here.</span></Link>
          </Paragraph>

          <Paragraph>
            <Title></Title>
          </Paragraph>

          <Title>Updated June 01, 2024</Title>
        </Section>
      </MainContainer>
  )
};

const Link = styled.a`
    text-decoration: none;
    color: #4B9FE1;
    cursor:pointer;
`;

const MainContainer = styled.div`
  display: flex;
  margin: 15px;
  @media (max-width: 770px) {
    flex-direction: column;
  }
`;

const Section = styled.div`
  flex: 3;
  padding: 25px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  font-size: 32px;
  font-weight: 700;
  flex-direction: column;
  text-align: center;
  width: 100%;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
`;

const Paragraph = styled.div`
  margin: 15px 0;
  line-height: 2;
`;

export default PrivacyPolicy;
