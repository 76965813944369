import { BehaviorSubject } from 'rxjs';
import { fetchWrapper } from '../utils/apiHandlers';
import { clientLogger } from '../utils/clientLogger';

const ISSERVER = typeof window === "undefined";
const user = !ISSERVER && JSON.parse(localStorage.getItem('user')||'{}')
// const user = !ISSERVER && JSON.parse(localStorage.getItem('user')||{})
const userSubject = new BehaviorSubject(user)

export const userContext = {
    user: userSubject.asObservable(),
    get userValue () { return userSubject.value },
    logout,
    login,
    register,
    updateprofile,
    getpublickey,
    setpublickey,
};

async function setpublickey(publickey: any){
    try{
        let usr = localStorage.getItem("user")
        if(usr){
            let user: any = JSON.parse(usr)
            user.public_key = publickey
            userSubject.next(user)
            localStorage.setItem('user', JSON.stringify(user))
        }else{
            return false
        }
        return true
    }catch(error){
        console.log(`Error getting public key: ${error}`)
        return false
    }
}

async function getpublickey(email: any){
    try{
        var response: any = await fetchWrapper.post("/api/crypto/getpublickey", {email: email}).then((res:any) => res)
        if(response.status==200){
            let usr = localStorage.getItem("user")
            if(usr){
                let user: any = JSON.parse(usr)
                user.public_key = response.public_key
                userSubject.next(user)
                localStorage.setItem('user', JSON.stringify(user))
            }else{
                return false
            }
        }
        return true
    }catch(error){
        console.log(`Error getting public key: ${error}`)
        return false
    }
}

function logout() {
    // remove user from local storage, publish null to user subscribers and redirect to login page
    localStorage.removeItem('user')
    userSubject.next({})
}

async function login(email:any, password:any) {
    return new Promise(async (resolve) =>{
        await fetchWrapper.post(`/api/authenticate/login`, { email, password }).then(async (user: any) =>{
            // console.log(`user : ${JSON.stringify(user)}`)
            
            userSubject.next(user)
            localStorage.setItem('user', JSON.stringify(user))

            resolve(user)
        }).catch((error: any) =>{
                console.log(`Error: ${error}`)
                resolve(null)
            })
    })
}

async function register(email:any, verificationcode: any, password:any) {
    const body = {
        email: email,
        verificationcode: verificationcode,
        password: password
    }
    return new Promise(async (resolve) =>{
        await fetchWrapper.post("/api/authenticate/registeruser", body).then((res:any) => {
            console.log(`res ${JSON.stringify(res)}`);
            if(res.status==200){
                userSubject.next(res.user)
                localStorage.setItem('user', JSON.stringify(res.user))
                resolve(true)
            }else{
                resolve(false)
            }
          }).catch((error: any)=> {
            clientLogger("info", error)
            resolve(false)
          })
    })
}

async function updateprofile(email: any, firstname: any, lastname: any, phonenumber: any, address: any){
    const body = {
        email: email,
        firstname: firstname,
        lastname: lastname,
        phonenumber: phonenumber,
        address: address,
    }
    return new Promise(async (resolve) =>{
        await fetchWrapper.post("/api/authenticate/updateprofile", body).then((res:any) => {
            if(res.status==200){
                userSubject.next(res)
                localStorage.setItem('user', JSON.stringify(res))
                resolve(true)
            }else{
                resolve(false)
            }
          }).catch((error)=> {
            console.log(`Error: ${error}`)
            resolve(false)
          })
    })
}

