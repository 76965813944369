export const sleep = (ms: number) =>{
  return new Promise( async (resolve) =>{
    await setTimeout(()=>{
      resolve(true)
    }, ms)
  })
}

export const formatName = (name: string) => {
  name = name.toLowerCase()
    if(name.length>1)
    {
        name = name[0].toUpperCase() + name.substring(1)
    }
    else{
        name = name.toUpperCase()
    }
    return name
}

export const formatPhoneInput = (newTxt: string, dashPos: number[]) => {
    newTxt = newTxt.replace(/-/g, "")
    dashPos.forEach((value, indx) => {
      if (newTxt.length >= dashPos[indx]) {
        newTxt =
          newTxt.slice(0, dashPos[indx] - 1) +
          "-" +
          newTxt.slice(dashPos[indx] - 1)
      }
    })
    return newTxt;
  };

export const formatNumberCommaNoDecimals = (amount: any) => {
    if(amount=="")
    {
        return "";
    }
    var formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    })
    return formatter.format(amount)
}

export const formatNumberComma = (amount: any) => {
    var formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 4,
    })
    return formatter.format(amount)
}

export const formatDollar = (amount: any) => {
    if(amount=="")
    {
        return "";
    }
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 4,
    })
    return formatter.format(amount)
}

export const formatDollarDigits = (amount: any, digitsafter: any) => {
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: digitsafter,
        maximumFractionDigits: digitsafter,
    })
    return formatter.format(amount)
}

export const formatDate = (dt: Date, stringformat: string) => {
    let strformat = ''
    for (let i = 0; i < stringformat.length; i++)
        strformat += char2num(dt, stringformat[i])
    return strformat
}

export const char2num = (dt: Date, c: string) => {
    switch (c) {
        case 'Y':
        return dt.getFullYear()
        case 'y':
        let y = dt.getFullYear()
        y = y % 100
        return y
        case 'M':
        let mth = dt.getMonth() + 1
        let sMth = mth.toString()
        if (mth < 10) {
            sMth = '0' + sMth
        }
        return sMth
        case 'd':
        let day = dt.getDate()
        let sDay = day.toString()
        if (day < 10) {
            sDay = '0' + sDay
        }
        return sDay
        case 'h':
        return dt.getHours()
        case 'm':
        return dt.getMinutes()
        case 's':
        return dt.getSeconds()
        case 'f':
        return dt.getMilliseconds()
        default:
        return c
    }
}

export function validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

export function EmailHeader(){
  return `<div><img src="cid:logo@miamigo.ai" style="width:50px;"/><a style="font-size:30px; font-weight:700; color:#6fa56f;" href="https://miamigo.ai/">MiAmigo.ai</a></div><br>`
}

export const Emailfooter = (email: any) =>{
  return `<br><div style="display:flex; align-items:center;"><img src="cid:logo@zappletz.com" style="width:30px;"/><a style="color:#6fa55f; font-size:24px; font-weight:700;" href="https://miamigo.ai">zappletz.com</a></div>
  <br><br>
  <h3>If you wish to unsubscribe please click <a href="https://ZappletZ.com/unsubscribe?unsubscribeemail=${email}">here</a></h3>
  
  `
}

export const generateStrongPassword = (length = 16) => {
  const upperCaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowerCaseChars = 'abcdefghijklmnopqrstuvwxyz';
  const numberChars = '0123456789';
  const specialChars = '!@#$%^&*()_+[]{}|;:,.?';

  const allChars = upperCaseChars + lowerCaseChars + numberChars + specialChars;
  let password = '';

  // Ensure at least one character from each category is present
  password += upperCaseChars[Math.floor(Math.random() * upperCaseChars.length)];
  password += lowerCaseChars[Math.floor(Math.random() * lowerCaseChars.length)];
  password += numberChars[Math.floor(Math.random() * numberChars.length)];
  password += specialChars[Math.floor(Math.random() * specialChars.length)];

  // Fill the rest of the password length with random characters
  for (let i = password.length; i < length; i++) {
    password += allChars[Math.floor(Math.random() * allChars.length)];
  }

  // Shuffle the password to ensure the order is random
  password = password.split('').sort(() => 0.5 - Math.random()).join('');

  return password
}


export const checkPasswordStrength = (password: any) => {
  let score = 0;

  if (password.length >= 8) score++;
  if (password.length >= 12) score++;
  if (/[A-Z]/.test(password)) score++;
  if (/[a-z]/.test(password)) score++;
  if (/[0-9]/.test(password)) score++;
  if (/[^A-Za-z0-9]/.test(password)) score++;
  return score
}