import React, {useState, useEffect} from 'react'
import styled from 'styled-components';


interface Prop{
}
const Pagenotfound: React.FC<Prop>=({})=>{
  return(
    <MainContainer>
    <h1>404 - Page not found</h1>    
    </MainContainer>
  );
}


const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 85vh;
  justify-content: center;
  align-items: center;
`

export default Pagenotfound
