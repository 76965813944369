import { fetchWrapper } from './apiHandlers';
import publicIp from "public-ip";

export const clientLogger = async (level: string, msg: string) => {
  // if (document.referrer.includes("localhost"))
  //   return;

  const config = {
    headers: { 'Content-Type': 'application/json'},
  };

  var clientIp = "No ip";
  try {
    clientIp = await publicIp.v4()

  } catch (e) {
    console.log("ip error")
  }

  msg += " referrer: " + document.referrer;
  msg = clientIp.concat(": ").concat(msg)

  await fetchWrapper.post("/api/clientLog", {level:level, msg:msg})
    .then((res:any) => {
      // console.log(`Here ${JSON.stringify(res)}`)
      return res.msg;
    })
    .catch((err: any) => {
      console.log("error: /api/clientLog ", err)
    })
};
